import { Avatar, Box, Button, Card, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { AttachMoney, CheckCircle, FiberManualRecord, FlagOutlined, Start, TrendingDown } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { addPeriod } from "../../utils/period";
import { unregist } from "../../api/line";
import { createPaymentPayOS } from "../../api/payos";
import { MAX_LINE_PROCESS } from "constants";
import { fCurrency, fCurrencyVNDAndShorter } from "utils";

export const LineProcess = ({ data, registerNewNode, unregistNode, fullWidth = false, updated}) => {
  const {
    isAuthenticated,
    user: { email },
  } = useAuth();

  var checkFull = false;
  if (data.countListProgress == data.countRegister) checkFull = true;

  const deposit = async (item) => {
    const data = new FormData();
    data.lineIdSeq = item.bladeUnitID + "_" + item.seq;
    data.lineId = item.bladeUnitID
    data.buyerEmail = item.userEmail;
    data.amount = item.deposit;
    data.description = "Đặt cọc " + item.bladeUnitID;
    let res = await createPaymentPayOS(data);
    toast.success("Create success");
  };

  const unregist = async (item) => {
    console.log("unregist");
    console.log(item);

    let res = await unregist({
      email: item.userEmail,
      bladeUnitID: item.bladeUnitID,
      seq: item.seq,
      token: "ABCXYZ",
    });
    toast.success("Hủy đăng ký thành công");
  };

  return (
    <Grid container direction={"column"} gap={2}>
      <Grid item container spacing={1} xs wrap="nowrap">
        {Array(fullWidth ? data.listProgress.length : MAX_LINE_PROCESS)
          .fill(0)
          .map((i, index) => {
            const value = fCurrencyVNDAndShorter(data.initPrice - data.depreciation * index);
            return (
              <Grid key={index} item xs>
                <Stack
                  sx={{
                    position: "relative",
                    height: "100%",
                    opacity: data.listProgress.length > index ? 1 : 0.0,
                  }}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {/* <FiberManualRecord /> */}
                  <Box
                    sx={{
                      zIndex: 10,
                      p: 1,
                      backgroundColor: "common.white",
                    }}
                  >
                    <Box
                      sx={{
                        p: 0.5,
                        backgroundColor: "primary.lighter",
                        color: "primary.main",
                        borderRadius: "11px",
                        // zIndex: 10
                      }}
                    >
                      <Typography variant="caption" fontWeight={"medium"} letterSpacing={0.5}>
                        {value}
                      </Typography>
                    </Box>
                  </Box>
                  {index !== 0 && (
                    <Box
                      sx={{
                        height: "2px",
                        width: "calc(100%)",
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "primary.main",
                        left: `calc(-50%)`,
                        // transform: "translateX(50%)",
                      }}
                    ></Box>
                  )}
                </Stack>
              </Grid>
            );
          })}
      </Grid>

      <Grid item container spacing={1} xs={1} wrap="wrap">
        {data.listProgress.map((i, index) => {
          const isEmpty = !i.userEmail;
          const isLastItem = index === data.listProgress.length - 1;
          const isPaid = false;
          const styleObj = {
            success: {
              borderColor: "success.main",
              backgroundColor: "success.lighter",
              borderWidth: 2,
            },
            pending: {
              borderColor: "warning.main",
              backgroundColor: "warning.lighter",
              borderWidth: 2,
            },
          };

          const lastItemStyle = isPaid ? styleObj.success : styleObj.pending;

          let renderItem;
          if (isEmpty) {
            renderItem = (
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  p: 1,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderStyle: "dashed",
                  //   backgroundColor: isRegisted ? "grey.200" : "white",
                  flexDirection: "column",
                  ...(isLastItem && {
                    borderColor: "primary.main",
                    borderWidth: 2,
                  }),
                }}
              >
                <IconButton
                  // disabled={isRegisted}
                  onClick={() => {
                    registerNewNode({
                      id: data.bladeUnitID,
                      seq: i.seq,
                      deposit: data.deposit,
                      lineId: data.id,
                    });
                  }}
                  disabled={!isAuthenticated}
                >
                  <AddIcon />
                </IconButton>
              </Card>
            );
          } else {
            renderItem = (
              <Tooltip
                title={
                  <>
                    <Typography variant="subtitle" className="textProduct">
                      {i.userEmail}
                    </Typography>
                  </>
                }
              >
                <Card
                  elevation={0}
                  variant="outlined"
                  sx={{
                    position: "relative",
                    px: 0.5,
                    py: 1,
                    height: "100%",
                    width: "100%",
                    maxWidth: "100%",
                    textAlign: "center",
                    backgroundColor: "primary.lighter",

                    ...(isLastItem && lastItemStyle),
                  }}
                >
                  <Stack alignItems={"center"} spacing={1}>
                    {isLastItem && (
                      <Tooltip title="Finisher">
                        <CheckCircle
                          fontSize="small"
                          sx={{ color: "success.main", position: "absolute", zIndex: 9, top: 2, right: 2 }}
                        />
                      </Tooltip>
                    )}
                    <Avatar className="avatar" alt={i.name} src="/upload/images/avatar-1.jpg" />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        maxWidth: "100%",
                      }}
                      className="textProduct mt-1"
                    >
                      {i.name}
                    </Typography>
                    {/* {data.startDate && (
                      <Typography variant="body2" className="textProduct">
                        {addPeriod({
                          period: data.period,
                          date: data.startDate,
                          periodInex: index,
                        }).format("DD/MM/YYYY")}
                      </Typography>
                    )} */}

                    {
                      (!updated && email === i.userEmail && !i.depositStatus) ? 
                        <Button variant="outlined" size="small" onClick={() => deposit(i)}>
                          Đặt cọc
                        </Button>
                      : (i.depositStatus) ? 
                        <Button variant="outlined" size="small" onClick={() => unregist(i)}>
                          Đã Đặt cọc
                        </Button>
                      : <></>
                    }
                    {/* {email === i.userEmail && i.depositStatus && !checkFull && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          unregistNode({
                            id: data.bladeUnitID,
                            seq: i.seq,
                            deposit: data.deposit,
                            lineId: data.id,
                          })
                        }
                      >
                        Đã Đặt Cọc
                      </Button>
                    )} */}
                  </Stack>
                </Card>
              </Tooltip>
            );
          }
          return (
            <Grid
              item
              key={i.lpID}
              xs
              maxWidth={"200px"}
              sx={{
                maxWidth: "200px",
              }}
            >
              {renderItem}
            </Grid>
          );
        })}
        {!fullWidth && <EmptySlots data={data} />}
      </Grid>
    </Grid>
  );
};

const EmptySlots = ({ data }) => {
  return (
    <>
      {Array(MAX_LINE_PROCESS - data.listProgress.length)
        .fill(0)
        .map((_, index) => (
          <Grid
            item
            key={index}
            xs
            maxWidth={"200px"}
            sx={{
              maxWidth: "200px",
              opacity: 0.1,
            }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "grey.300",
              }}
              elevation={0}
            ></Card>
          </Grid>
        ))}
    </>
  );
};
export const LineMoneyDetail = ({ data }) => {
  return (
    <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
      <InfoItem icon={<Start />} label={"Khởi đầu"} value={fCurrency(data.initPrice)} />
      <InfoItem icon={<AttachMoney />} label={"Đặt cọc"} value={fCurrency(data.deposit)} />
      <InfoItem icon={<TrendingDown />} label={"Khấu hao"} value={fCurrency(data.depreciation)} />
      <InfoItem icon={<FlagOutlined />} label={"Kết thúc"} value={fCurrency(data.endPrice)} />
    </Stack>
  );
};
const InfoItem = ({ icon, label, value }) => {
  const theme = useTheme();
  return (
    <Stack
      component={Card}
      sx={{ px: 2, py: 1, width: "100%", backgroundColor: theme.palette.grey[100] }}
      elevation={0}
      direction={"row"}
      gap={2}
    >
      {icon}
      <Stack>
        <Typography variant="subtitle2" color={"GrayText"}>
          {label}
        </Typography>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
