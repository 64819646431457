import { useEffect, useMemo, useState } from "react";
import { emphasize, Breadcrumbs, Button, Chip, styled, Grid, Paper, Container, InputAdornment } from "@mui/material/";
import { Home } from "@mui/icons-material";

import { FaCloudUploadAlt } from "react-icons/fa";
import { GrBladesHorizontal } from "react-icons/gr";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FormProvider, useForm } from "react-hook-form";
import { RhfAutocomplete, RhfSelect, RhfUploadMultipleFile } from "../../components/form";
import RhfTextField, { RhfNumber } from "../../components/form/RhfTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLineFormData } from "../../api/line";
import { toast } from "react-toastify";
import { lineReq, periodCounts, periods, registType } from "../../constants";
import { useQuery } from "@tanstack/react-query";
import { RhfDatePicker } from "../../components/form/RhfDatePicker";
import { useNavigate } from "react-router-dom";
import { getSearchBlades, getBladeUnitByBladeCD } from "api/blade";
import { getBrands } from "api/brand";
import { getCodesByStandardEnglishId } from "api/code";
// Breadcrum code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
const RhfCurrency = ({ ...props }) => {
  return (
    <RhfNumber
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      endAdornment={<InputAdornment position="end">vnd</InputAdornment>}
      placeholder="0.00"
      thousandSeparator
      {...props}
    />
  );
};
const lineValidationSchema = yup.object({
  period: yup.string().required("Required"),
  periodCnt: yup.string().required("Required"),
  // lineReq: yup.string().required("Required"),
  // initPrice: yup.number().required("Required"),
});

const LineCreate = () => {
  const navigate = useNavigate();

  const { data: brands, isLoading: isLoadingBrands } = useQuery({
    queryKey: ["brandList"],
    queryFn: async () => {
      return getBrands();
    },
  });
  const dataBrand = brands?.data || []; // ?.data ?? []

  const methods = useForm({
    resolver: yupResolver(lineValidationSchema),
    defaultValues:{
      period: periods[0].value,
      periodCnt: periodCounts[0].value,
      // lineReq: lineReq[0].value,
      registType: registType[0].value,
      remainingPercent: 0,
    }
  });

  const { handleSubmit, reset, setValue, watch } = methods;
  const watchBladeCD = watch("bladeCD");
  const { data: bladeUnitRes, isLoading: isLoadingBladeUnit } = useQuery({
    queryKey: ["bladeUnit", watchBladeCD],
    queryFn: async () => {
      return getBladeUnitByBladeCD(watchBladeCD);
    },
  });
  const standardEnglishId = "REMAINING_PERCENT";
  const { data: codes, isLoading: isLoadingCodes } = useQuery({
    queryKey: ["code", standardEnglishId],
    queryFn: async () => {
      return getCodesByStandardEnglishId(standardEnglishId);
    },
  });
  const remainingPercents = useMemo(() => {
    const data = codes?.data || [];
    return data.map((item) => ({
      value: item.cdv,
      label: item.cdName,
    }));
  }, [codes]);

  const [selectedBrandCD, setSelectedBrandCD] = useState(null);

  const { data: blades, isLoading: isLoadingBlades } = useQuery({
    queryKey: ["bladeList", selectedBrandCD],
    queryFn: async () => {
      if (!selectedBrandCD) return [];
      const response = await getSearchBlades({ brandCD: selectedBrandCD });
      return response.data || [];
    },
    enabled: !!selectedBrandCD,
  });

  const bladeOptions = useMemo(() => {
    return blades?.map(blade => ({
      value: blade.bladeCD,
      label: blade.bladeName
    })) || [];
  }, [blades]);

  useEffect(() => {
    setValue("bladeCD", "");
  }, [selectedBrandCD, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    try {
      data.email = localStorage.getItem("email");
      if (data.image != undefined && data.image != null) {
        const images = data.image;

        console.log(images);
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i]);
        }
      } else {
        formData.append("images", null);
      }
      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              ...data,
              image: undefined,
            }),
          ],
          { type: "application/json" }
        )
      );

      const res = await createLineFormData(formData);
      toast.success("Create success");

      // window.location.href = "/line-list";
      navigate("/line-list");
    } catch (error) {
      console.error(error);
      toast.error("Create fail");
    }
  };

  return (
    <>
      <Container className="right-content w-100">
        <Paper sx={{ p: 2, display: "flex" }}>
          <h5 className="mb-0">
            <GrBladesHorizontal /> Line Create
          </h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
            <StyledBreadcrumb component="a" href="#" label="Products" />
            <StyledBreadcrumb label="Line Create" />
          </Breadcrumbs>
        </Paper>

        <FormProvider {...methods}>
          <Paper component="form" sx={{ mt: 3, px: 2, py: 3 }} className="form" onSubmit={handleSubmit(onSubmit)}>
            <h5 className="mb-4">
              <IoIosInformationCircleOutline /> Line Information
            </h5>

            <Grid container spacing={2} wrap="wrap">
              <Grid item xs={12}>
                <RhfUploadMultipleFile shouldPreview={true} name="image" label={"Image"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <RhfAutocomplete
                  name={"brandCD"}
                  loading={isLoadingBrands}
                  options={dataBrand}
                  keyValue={"brandCD"}
                  label={"Brand"}
                  getOptionLabel={(option) => option.brandName}
                  onChange={(option) => {
                    setSelectedBrandCD(option.brandCD);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RhfAutocomplete
                  name={"bladeCD"}
                  loading={isLoadingBlades}
                  options={bladeOptions}
                  // keyValue={"bladeCD"}
                  // label={"Blade"}
                  // getOptionLabel={(option) => option.bladeName}
                  // onChange={(newValue) => {
                  //   setBladeCD(newValue);
                  // }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <RhfDatePicker name="startDate" label={"Start Date"} />
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <RhfAutocomplete
                  name={"bladeUnitID"}
                  loading={isLoadingBladeUnit}
                  options={bladeUnitRes?.data ?? []}
                  keyValue={"unitId"}
                  label={"Blade Unit"}
                  getOptionLabel={(option) => option?.unitId}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <RhfSelect name="period" label="Thời hạn" options={periods}  />
              </Grid>
              <Grid item xs={12} md={6}>
                <RhfSelect name="periodCnt" label="Số lần trả" options={periodCounts} />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <RhfSelect name="lineReq" label="Tình trạng" options={lineReq} />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <RhfSelect name="registType" label="Tình trạng" options={registType} />
              </Grid>
              <Grid item xs={12} md={6}>
                <RhfSelect name="remainingPercent" label="Tình trạng vợt (%)" options={remainingPercents} />
              </Grid>
              {/* <Grid item xs={6}>
                <RhfCurrency name="initPrice" label="Khởi đầu" />
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <RhfCurrency name="fee" label="Phí" />
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <RhfCurrency name="endPrice" label="Kết Thúc" />
              </Grid>
              <Grid item xs={12} md={6}>
                <RhfCurrency name="depreciation" label="Khấu hao" />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <RhfCurrency name="deposit" label="Đặt cọc" />
              </Grid>
              <Grid item xs={12}>
                <RhfTextField name="note" label="Note" rows={2} />
              </Grid>
            </Grid>
            <Button sx={{ mt: 3 }} type="submit" variant="contained">
              <FaCloudUploadAlt /> &nbsp; PUBLISH AND VIEW
            </Button>
          </Paper>
        </FormProvider>
      </Container>
    </>
  );
};

export default LineCreate;
