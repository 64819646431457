import { api } from ".";

export const createPaymentPayOS = async ({
    lineIdSeq,
    lineId,
    amount,
    description,
    orderCode,
    buyerName,
    buyerEmail,
    buyerPhone,
    buyerAddress
}) => {
    const response = await api.post("public/payos/insert-url", {
        lineIdSeq,
        lineId,
        amount,
        description,
        orderCode,
        buyerName,
        buyerEmail,
        buyerPhone,
        buyerAddress
    });
    console.log(response.data);
    window.open(response.data.checkoutUrl, "_blank", "noreferrer");
    return response;
};

export const getListPayment = async (params) => {
    const response = await api.post("public/payos/payment/search", {
        ...params,
    });
    return response;
};

export const getPaymentDetail = async (paymentID) => {
    const response = await api.get(`public/payos/payment/${paymentID}`);
    return response;
};

// export const createPaymentPayOS = async (params) => {
//     const response = await api.post("public/payos/insert-url", {
//         ...params,
//     });
//     return response;
// };
