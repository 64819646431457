import { useParams, useSearchParams } from "react-router-dom";
import LineActivity from "./LineActivity";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getLineDetail, updateDepositStatus } from "../../api/line";
import { Suspense, useEffect, useState } from "react";
import LineDetail from "./LineDetail";
import { Card, CardContent, CardHeader, Container, Stack } from "@mui/material";
import { LineProcess } from "components/line";
import RegisterNodePopup from "components/line/RegisterNodePopup";
import { useHandleRegisterLineProgressNode } from "hooks/useHandleRegisterLineProgressNode";
import { useHandleUnregisterLineProgressNode } from "hooks/useHandleUnregisterLineProgressNode";
import UnregistNodePopup from "components/line/UnregistNodePopup";
// import { api } from "../../api";
// import { updateDepositStatus } from "../../api/line"

const LineDetailPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [updated, setUpdated] = useState(false);

  useEffect(()=>{
    const update= async () => {
      if(searchParams.get("actionupdate")){
        const result= await updateDepositStatus(
          {
            lineIdSeq: searchParams.get("lineIdSeq"),
            orderCode: searchParams.get("orderCode")
          });
        if(result){
          setUpdated(true);
        }
      }
    };

    update();
  },[searchParams.get("actionupdate")]);
  let { lineID } = useParams();

  const { data: line } = useQuery({
    queryKey: ["line", lineID],
    queryFn: async () => {
      return getLineDetail(lineID);
    },
    suspense: true,
  });

  const lineData = line?.data;
  const lineProgresses = line?.data?.listProgress || [];
  const queryClient = useQueryClient();

  const { showModalPopupAdd, setShowModalPopupAdd, registerNewNode, handleRegister, currentNode } =
    useHandleRegisterLineProgressNode({
      onComplete: () => {
        queryClient.invalidateQueries({ queryKey: ["line"] });
      },
    });

    const { showModalPopupUnregist, setShowModalPopupUnregist, unregistNode, handleUnregist } = // , unregistNode, handleUnregist, currentNode
    useHandleUnregisterLineProgressNode({
      onComplete: () => {
        // navigate(`/line/${currentNode.bladeUnitId}`);
        queryClient.invalidateQueries({ queryKey: ["line"] });
      },
    });


  return (
    <Container maxWidth="xl">
      <Stack spacing={4} className="right-content w-100">
        {lineData && <LineDetail line={lineData} />}
        <Card>
          <CardHeader title="Line Process" />
          <CardContent>
            <LineProcess data={lineData} fullWidth={true} registerNewNode={registerNewNode} unregistNode={unregistNode} updated={updated} />
          </CardContent>
        </Card>
        <LineActivity datas={lineProgresses} />
      </Stack>

      <RegisterNodePopup
        onClose={() => setShowModalPopupAdd(false)}
        open={showModalPopupAdd}
        handleRegister={() => {
          handleRegister();
        }}
        // lineID={lineID}
        // token={token}
        // email={email}
      />
      <UnregistNodePopup
        onClose={() => setShowModalPopupUnregist(false)}
        open={showModalPopupUnregist}
        handleUnregist={() => {
          handleUnregist();
        }}
      />
    </Container>
  );
};

const withSuspense = (Component) => (props) => {
  return (
    <Suspense fallback={"loading!"}>
      <Component {...props} />
    </Suspense>
  );
};

// const updateDepositStatus = async ({
//   lineIdSeq,
//   orderCode
// }) => {
//   try {
//     await updateCommentForLineProgress(lineIdSeq, orderCode);    
//     toast.success("comment success");
   
//   } catch (error) {}
// };
export default withSuspense(LineDetailPage);
