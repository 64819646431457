import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { MyContext } from "../../App";
import { RhfAutocomplete } from "components/form";
import { TablePagination } from "components/table";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material/";
import { Home, ExpandMore } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import StyledBreadcrumb from "../../components/Breadcrumb";
import { LineTable } from "./LineTable";
import { useQuery } from "@tanstack/react-query";
import { getLines, getLinesSearch } from "api/line";
import { getBrands } from "api/brand";
import { getSearchBlades, getBladeUnitByBladeCD } from "api/blade";
import useAuth from "../../hooks/useAuth";

const lineValidationSchema = yup.object({
  period: yup.string().required("Required"),
  periodCnt: yup.string().required("Required"),
});

const Line = () => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedBrandCD, setSelectedBrandCD] = useState("");
  const [selectedStatusLine, setSelectedStatusLine] = useState("");
  const context = useContext(MyContext);
  const { isAuthenticated } = useAuth();
  const [searchData, setSearchData] = useState({
    orderCode: "",
    email: "",
    brandCD: "",
    statusLine: "",
  });

  useEffect(() => {
    // Hide sidebar
    context.setIsHideSidebarAndHeader(false);
    window.scrollTo(0, 0);
  });

  const methods = useForm({
    resolver: yupResolver(lineValidationSchema),
  });

  const { data: dataList, isLoading } = useQuery({
    queryKey: ["lineList", page, rowsPerPage, searchData],
    queryFn: async () => {
      // if (!selectedBrandCD) return [];
      return await getLinesSearch({
        pageNum: page,
        pageSize: rowsPerPage,
        ...searchData,
      });
    },
  });

  const { data: dataBrand } = useQuery({
    queryKey: ["brandList"],
    queryFn: async () => {
      return getBrands();
    },
  });

  // const lines = dataList?.data || [];
  const brands = dataBrand?.data || [];

  const { total, lines } = useMemo(() => {
    if (dataList) {
      return { total: dataList?.data.totalResults, lines: dataList?.data.pageResults };
    }
    return { total: 0, lines: [] };
  }, [dataList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(1);
  };

  const handleSearch = (event) => {
    event.preventDefault();

    setSearchData({
      ...searchData,
      brandCD: selectedBrandCD,
      statusLine: selectedStatusLine
    })
  }

  const handleLineStatusChange = (event) => {
    setSelectedStatusLine(event.target.value);
  };

  // const { data: blades, isLoading: isLoadingBlades } = useQuery({
  //   queryKey: ["bladeList", selectedBrandCD],
  //   queryFn: async () => {
  //     if (!selectedBrandCD) return [];
  //     const response = await getSearchBlades({ brandCD: selectedBrandCD });
  //     return response.data || [];
  //   },
  //   enabled: !!selectedBrandCD,
  // });

  // useEffect(() => {
  //   setValue("bladeCD", "");
  // }, [selectedBrandCD, setValue]);

  // Nếu brand có sub brand, setSubBranchVal
  // const handleClickBrand = async (event) => {
  //   try {
  //     setBrandVal(event.target.value);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // Popup
  const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);
  // const [lineID, setLineID] = useState(null);
  const openPopupAdd = () => {
    setShowModalPopupAdd(!showModalPopupAdd);
    // setLineID(lineID);
  };

  return (
    <>
      <section className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4">
          <h5 className="mb-0">Line List</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
            <StyledBreadcrumb label="Lines" deleteIcon={<ExpandMore />} />
          </Breadcrumbs>
        </div>

        <div className="card shadow border-0 p-3 mt-4">
          <div className="row cardFilters mt-3 mb-3">
            <div className="col-md-2">
              <div className="logoutBox" onClick={openPopupAdd}>
                <Link to={"/line-create"}>
                  <Button variant="contained" disabled={!isAuthenticated}>Tạo line</Button>
                </Link>
              </div>
            </div>
            <div className="offset-md-3 col-md-7">
              <Box sx={{ minWidth: 120 }}>
                <FormProvider {...methods}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Button variant="contained" color="primary" onClick={handleSearch}>Tìm kiếm</Button>
                    </Grid>
                    <Grid item xs={4}>
                      <RhfAutocomplete
                        name={"brandCD"}
                        // loading={isLoadingBrands}
                        options={brands}
                        keyValue={"brandCD"}
                        label={"Brand"}
                        getOptionLabel={(option) => option.brandName}
                        onChange={(option) => {
                          setSelectedBrandCD(option.brandCD);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Trạng thái</InputLabel>
                        <Select
                          // size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="TRẠNG THÁI THANH TOÁN"
                          onChange={handleLineStatusChange}
                        >
                          <MenuItem value={"0"}>Tất cả ALL</MenuItem>
                          <MenuItem value={"1"}>PENDING</MenuItem>
                          <MenuItem value={"2"}>ACTIVE</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormProvider>
              </Box>
            </div>
          </div>

          <LineTable rows={lines} />

          <div className="d-flex tableFooter">
            <TablePagination
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Line;
