import { Box, Button, Grid, Stack, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { LineMoneyDetail, LineProcess } from "../../components/line";
import useAuth from "../../hooks/useAuth";
import HoverImage from "./HoverImage";


const LineRow = ({ row, registerNewNode, unregistNode, removeLineNode }) => {
  // Get current email
  const {
    isAuthenticated,
    user: { email },
  } = useAuth();

  const imgTemp = "/upload/images/avatar-code.png";
  const imageDetails = [imgTemp, imgTemp, imgTemp, imgTemp];
  return (
    <TableRow key={row.name}>
      <TableCell>
        <Box className="imgProductArea">
          <Link to={`/line/${row.bladeUnitID}`}>
            <img
              src={row.bladeImageFull || "/upload/images/avatar-code.png"}
              style={{
                width: "100%",
              }}
              className="imgProduct"
            />
          </Link>
          {/* <div className="progress mt-2">
            <div className="progress-bar" style={{ width: `${row.remainingPercent || 0}%` }}></div>
          </div> */}
          {/* <h6 className="textProduct mt-2">{row.bladeUnitID}</h6>
          <h6 className="textProduct mt-2">{row.depreciation}</h6> */}
          <h6 className="textProduct mt-2">{row.bladeFullName}</h6>
          <h6 className="textProduct mt-2">{row.periodCnt}</h6>
          <h6 className="textProduct mt-2">{row.remainingPercent}</h6>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"center"}
            className="mt-2"
            style={{
              zIndex: 100,
              position: "relative",
            }}
          >
            {imageDetails.map((item, index) => {
              return <HoverImage key={index} image={item} />;
            })}
          </Stack>

          {email === row.emailCreator && row.countListProgress > row.countRegister ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                removeLineNode({
                  bladeUnitID: row.bladeUnitID,
                  lineId: row.id,
                });
              }}
            >
              Hủy Line
            </Button>
          ) : (
            ""
          )}
        </Box>
      </TableCell>

      <TableCell>
        <Grid container direction={"column"} gap={3}>
          <Grid item>
            <LineMoneyDetail data={row} />
          </Grid>
          <Grid item>
            <LineProcess data={row} registerNewNode={registerNewNode} unregistNode={unregistNode} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export { LineRow };
